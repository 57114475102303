import auth0 from 'auth0-js'
import Vue from 'vue'

// import dotenv from 'dotenv'
const host = document.location.origin
const url = host + "/env.json"
const port = document.location.port
let auth = null

const getAuth = function (next) {
  fetch(url)
  .then(json => json.text())
  .then(json => {

    if (!json || typeof json == "object" || json.length < 2 || json[0] != '{')
       json = '{}';
    const env = JSON.parse(json)

    console.log("host", host, "env", env.AUTH0_DOMAIN)
    // const host = `http://localhost:${port}`
    const callback = env.AUTH0_CALLBACK_URL || host + `/#callback`

    // exchange the object with your own from the setup step above.
    let webAuth = null;
    let e = null;
    try {
      webAuth = new auth0.WebAuth({
        domain: env.AUTH0_DOMAIN,
        clientID: env.AUTH0_CLIENT_ID,
        // secret: env.AUTH0_CLIENT_SECRET,
        // make sure port is
        redirectUri: callback, // env.AUTH0_CALLBACK_URL || `http://localhost:${port}/callback`,
        // we will use the api/v2/ to access the user information as payload
        // audience: env.AUTH0_DOMAIN + '/api/v2/',
        responseType: 'token id_token',
        scope: 'openid profile',
        state: 'state-x'
      })
    }
    catch (err) {
        e = err;
    }

    let auth = new Vue({
      computed: {
        token: {
          get: function() {
            return localStorage.getItem('id_token')
          },
          set: function(id_token) {
            localStorage.setItem('id_token', id_token)
          }
        },
        accessToken: {
          get: function() {
            return localStorage.getItem('access_token')
          },
          set: function(accessToken) {
            localStorage.setItem('access_token', accessToken)
          }
        },
        expiresAt: {
          get: function() {
            return localStorage.getItem('expires_at')
          },
          set: function(expiresIn) {
            let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime())
            localStorage.setItem('expires_at', expiresAt)
          }
        },
        user: {
          get: function() {
            var json = { nickname: '', name: '', id_token: ''}
            try {
              json = JSON.parse(localStorage.getItem('user')) || json
            } catch (err) {
              console.error("err json", err)
            }
            console.log('get user', json)
            return json
          },
          set: function(user) {
            localStorage.setItem('user', JSON.stringify(user))
          }
        }
      },
      methods: {
        login() {
          webAuth.authorize()
        },
        logout() {
          return new Promise((resolve, reject) => { 
            localStorage.removeItem('access_token')
            localStorage.removeItem('id_token')
            localStorage.removeItem('expires_at')
            localStorage.removeItem('user')
            webAuth.logout({
              returnTo: host + '/', // Allowed logout URL listed in dashboard
              clientID: env.AUTH0_CLIENT_ID
            })
          })
        },
        isAuthenticated() {
          return new Date().getTime() < this.expiresAt && this.user && this.user.name != ''
        },
        handleAuthentication() {
          return new Promise((resolve, reject) => {  
            webAuth.parseHash((err, authResult) => {

              if (authResult && authResult.accessToken && authResult.idToken) {
                this.expiresAt = authResult.expiresIn
                this.accessToken = authResult.accessToken
                this.token = authResult.idToken
                // localStorage.setItem('user', JSON.stringify(authResult.idTokenPayload))
                this.user = authResult.idTokenPayload
                resolve()

              } else if (err) {
                this.logout()
                reject(err)
              }

            })
          })
        }
      }
    })

    window.auth = auth
    if (next)
      next(auth)
  })

  return auth;
}

export default getAuth // promise
/* {
  install: function(Vue) {

    getAuth(auth => {
      Vue.prototype.$auth = auth
    })
  }
} */

