import Vue from 'vue'
import Router from 'vue-router'

// import Home from '@/views/Home.vue'
import Home from './views/Home.vue'
import Start from './views/Start.vue'
import Callback from './views/Callback.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'start',
      component: Start
    },
    { path: '/auth',
      name: 'home',
      component: Home
    },
    {
      path: '/#login',
      name: 'login',
      component: Home
    },    
    {
      path: '/#callback',
      name: 'callback',
      component: Callback
    },
    {
      path: '/#error',
      name: 'error',
      component: Callback
    }
    // [{ path: '/:pathMatch(.*)', component: NotFoundComponent }], 
  ]
})

// very basic "setup" of a global guard
router.beforeEach((to, from, next) => {

  var $auth = window.auth
  if (typeof router.app.$auth === "undefined") 
  {
    router.app.$auth = window.auth
  } else {
    $auth = router.app.$auth
  }
  window.auth = $auth

  if (!$auth.user || !$auth.user.name) {
    router.app.$auth.user = { name: "", login: ""}
    $auth = router.app.$auth
  }
  // console.log("to.name", to.name, $auth.user.name)
  const hash = to.hash
  
  if (hash.substr(0, 7) == "#access"
   || hash.substr(0, 7) == "auth/#access"
  ) {
    // hash callback
    $auth.handleAuthentication()
    .then((data) => {
       window.location.href = window.location.origin + "/auth/#"
       window.location.reload(false)
    })
  }
  else {
    let user = localStorage.getItem("user")

    if (to.name == 'error' 
     || to.name == 'home' 
     || to.name == 'start') {
      next()
    } else
    if(to.name == 'callback') { // check if "to"-route is "callback" and allow access
      next()
    } else if ($auth && $auth.isAuthenticated()) { // if authenticated allow access
      next()
    } else if (to.name == "login" && $auth) { // trigger auth0's login.
      $auth.login()
    }
  }
})

export default router