<template>
  <div class="dashboard">
    <nav class="navbar navbar-dark bg-dark">

      <span style="float: left; text-align: left; color: green;">
        <a class="navbar-brand" href="#">
          <img src="https://a.storyblok.com/f/39898/1024x1024/dea4e1b62d/vue-js_logo-svg.png" width="40" height="40">
        </a>
        <span style="color: silver">
        | <a href="https://baget.lan">baget.lan</a> (localhost:90)
        </span>
      </span>
      <span style="color: white;">Hello Baget</span>

      <div>
        <span class="text-muted font-weight-light px-2">{{$auth.user.name}}</span>
        <a class="btn btn-primary btn-sm" 
          href="/auth/#">
          Login
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data () {
    return {
      user: {},
      stories: []
    }
  },
  mounted() {
    // this.user = this.getUser();
  },
  methods: {
    getUser() {
      return localStorage.setItem('user') || { "name": ""};
    }
  }
}
</script>

<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');

.btn-primary {
  background: #468f65;
  border: 1px solid #468f65;
}
.card {
  text-decoration: none;
  color: #000;
}
</style>
