import Vue from 'vue'

import App from './App.vue'
import router from './router'
import getAuth from './auth'

getAuth(auth => {
  Vue.use({ 
    install: function(_Vue) {
      _Vue.prototype.$auth = auth
    }
  })

  Vue.config.productionTip = false

  new Vue({
    router,
    render: h => h(App)
  }).$mount('#root')
})
